* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Lexend";
}

body {
    background: #00090c;
}

section {
    width: 100%;
    overflow: hidden;
}

.sea {
    position: absolute;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 400px;
}

.land {
    position: absolute;
    bottom: 200px;
    object-fit: cover;
    width: 100%;
}

.text {
    position: absolute;
    bottom: 50%;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.float-anim {
    animation: floatcross 2s infinite;
}

.float-updown {
    animation: floatUpDown 2s infinite;
}

.breathe {
    animation: breathe 2s infinite;
}

.cursor-pointer {
    cursor: pointer;
}

.no-cursor-anim:hover {
    animation-play-state: paused;
}

.crab-with-resume {
    position: fixed;
    right: 10px;
    bottom: 20px;
    min-width: 100px;
    width: 5%;
    z-index: 100;
    transition: all 0.3s;
}

.crab-with-resume:hover {
    transform: scale(1.1) translateY(-10px);

    cursor: pointer;
}

.land-crab {
    position: fixed;
    right: -50px;
    bottom: -10px;
    height: 50px;
    z-index: 99;
    border-radius: 10px;
}

@keyframes floatcross {
    0% {
        transform: translate(0, 0) scale(1);
    }
    50% {
        transform: translate(5px, -5px) scale(1.01);
    }
    100% {
        transform: translate(0, 0) scale(1);
    }
}

@keyframes floatUpDown {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: translateY(5px) scale(1.01);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes breathe {
    0% {
        transform: translateY(0) scale(1);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}

@media (max-width: 700px) {
    .crab-with-resume {
        display: none;
    }

    .land-crab {
        display: none;
    }
}
