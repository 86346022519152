@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Lexend:wght@300;400;500;600&display=swap");

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #4c4f68;
}

::-webkit-scrollbar-thumb {
    background: #acb5d9;
    border-radius: 5px;
    width: 10px;
}
