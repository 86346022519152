.five {
    background: linear-gradient(180deg, #00171e 0%, #00090c 100%);
    position: relative;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    min-height: 100vh;
}

.sea-bed {
    width: 100%;
    height: 150px;
    position: absolute;
    bottom: 0;
    z-index: 30;
}

.board-container {
    height: 300px;
    width: 100%;
    max-width: 400px;
    z-index: 20;
    display: flex;
    padding: 20px;
}

.board {
    height: 300px;
    width: 100%;
    background-color: #99a7d4;
    border: 5px #c8daff solid;
    border-radius: 12px;
    position: relative;
    z-index: 20;
    padding: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.left-view {
    height: 300px;
    background-color: #4d4f68;
    width: 40px;
    border-top-left-radius: 30px;
    z-index: 10;
    margin-right: -10px;
}

.props-container {
    position: absolute;
    display: flex;
    align-items: flex-end;
    width: 480px;
    bottom: 30px;
    z-index: 30;
    justify-content: space-between;
}

.board {
    color: #4d4f68;
    text-align: center;
}

.dark-gradient {
    height: 100%;
    width: 100%;
    position: absolute;
    background: radial-gradient(
        49.94% 49.94% at 50% 50.06%,
        rgba(1, 24, 31, 0.49) 5.28%,
        #01181f 100%
    );
    z-index: 50;
}

.angler {
    z-index: 60;
    position: relative;
}

.five-comment {
    position: absolute;
    left: 50%;
    white-space: nowrap;
}

.bottom-text {
    position: absolute;
    bottom: 5px;
    left: 10px;
    /* transform: translateX(-50%); */
    z-index: 60;
    font-size: 14px;
    color: #7b5334;
    font-weight: 300;
}

.ruin-icons-container {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: flex-end;
    padding: 30px 15px;
}

.ruin-icon {
    width: 30px;
    transition: all 0.3s;
}

.ruin-icon:hover {
    cursor: pointer;
    scale: 1.3;
}

.resume-text {
    text-decoration: underline;
    font-size: 14px;
    color: #4d4f68;
}

.resume-text:hover {
    cursor: pointer;
}

.diver-med {
    position: absolute;
    bottom: 30px;
    z-index: 30;
    left: calc(50% + 130px);
}

.ruins {
    position: absolute;
    bottom: 30px;
    z-index: 30;
    left: calc(50% - 250px);
}

.board-text {
    font-size: 14px;
    font-weight: 400;
}
